th.MuiTableCell-head .MuiBadge-root svg,

th.MuiTableCell-head,
th.MuiTableCell-head .MuiButtonBase-root {
    color: #ffffff !important;
    opacity: 1 !important;
    font-size: 12px;
}
th.MuiTableCell-head svg {
    color: #ffffff;
    opacity: 1;
    font-size: 12px;
}
body {
    font-family: 'Poppins', sans-serif;
}

th.MuiTableCell-head .MuiBadge-root svg,
th.MuiTableCell-head .MuiTableSortLabel-icon {
    font-size: 14px;
}
th.MuiTableCell-head .MuiCollapse-wrapper {
    background-color: #ffffff !important;
    padding: 0px 5px 0px 5px !important;
    height: 25px !important;
}



th.MuiTableCell-head .MuiCollapse-wrapper .MuiIconButton-sizeSmall .MuiSvgIcon-fontSizeMedium {
    color: #000000c7;
}


th.MuiTableCell-head .MuiCollapse-wrapper .MuiInputAdornment-root span {
    cursor: pointer !important;
}

.css-1w86f15 {
    justify-content: center !important;
    padding-bottom: 30px;
}

.toast {
    display: none !important;
    visibility: hidden !important;
}

.toast--danger {
    display: none !important;
}
