.rs-picker-menu {
    z-index: 1400 !important; /* Adjust the z-index value as needed */
  }

  
  .base-Popper-root {
    z-index: 1400 !important; }

.css-1ols1k-MuiPopper-root {
    z-index: 1400 !important; }

    .rs-checkbox-checked .rs-checkbox-wrapper .rs-checkbox-inner::before,
.rs-checkbox-indeterminate .rs-checkbox-wrapper .rs-checkbox-inner::before {
    border-color: #106881 !important
    ;
    background-color: #106881 !important
    ;
}
rs-picker-has-value .rs-btn .rs-picker-toggle-value,
.rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
    color:  #000000 !important;
}
.rs-picker-value-count {
    /* Add your custom styles here */
    color: #ffffff; /* Change text color to white */
    background-color: #106881 !important; /* Change background color to blue */
    padding: 4px 8px; /* Add padding around the count */
    border-radius: 50%; /* Make the count badge round */
    font-size: 13px; /* Adjust font size */
}
.rs-radio-wrapper{
    top: 0px!important;
}
.rs-radio-checker{
    min-height: 0px!important;
    padding-top: 0px!important;
    padding-bottom: 0px!important;

}
